/**
 * Notes:
 * - Color codes: https://htmlcolorcodes.com/
 */

import * as React from "react";
import { ResponsiveAppBar } from "../components/appBar";

/** All pictures, require ts-ignore */

// single page app
const IndexPage = () => {
    return (
        <>
            <ResponsiveAppBar />
        </>
    )
}

export default IndexPage
